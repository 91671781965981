import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f09ee322"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "sub-content"
};
const _hoisted_3 = {
  class: "pc-guide-item"
};
import { onMounted } from "vue";
import { PreviewImages } from "../../utils/tools";
export default {
  setup(__props) {
    onMounted(() => {
      document.title = "推广规则";
    });
    function showBigImg(index, img) {
      PreviewImages(index, [img]);
    }
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_image, {
        class: "pc-guide-cover",
        "lazy-load": "",
        src: _ctx.$imgUrlFilter('https://p6.moimg.net/mdwxapp/protomotion_rule.png', 690),
        onClick: _cache[0] || (_cache[0] = $event => showBigImg(0, 'https://p6.moimg.net/mdwxapp/protomotion_rule.png'))
      }, null, 8, ["src"])])])]);
    };
  }
};